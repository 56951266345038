<style lang="scss">
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

body {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

h1 {
  font-weight: 400;
}

.content,
.result {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  margin-top: 20px;
  width: 700px;
  max-width: 90vw;
}

.step {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

h3 {
  border-bottom: 1px dotted #ccc;
  padding-bottom: 5px;
}

strong {
  font-weight: 500;
}

input[type="number"] {
  padding: 5px 10px;
  font-size: 1em;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 3px 0;
  max-width: 100px;
  font-family: monospace;
}

label {
  user-select: none;
  color: #555;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
  .explanation {
    color: #aaa;
    font-size: 0.7em;
    background: #f8f8f8;
    border-radius: 4px;
    padding: 4px 7px;
    margin: 5px 0;
    margin-left: 25px;
  }
  > input[type="checkbox"] {
    margin-right: 10px;
  }
  > input[type="radio"] {
    margin-right: 10px;
  }

  &.text {
    display: flex;
    padding: 0;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

a {
  color: rgb(37, 187, 135);
  text-decoration: none;
}

.result {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 100px 0 200px;
  grid-row-gap: 40px;
  padding-bottom: 50px;
  border-color: rgb(37, 187, 135);
  background-color: rgba(37, 187, 135, 0.05);

  .entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    strong {
      font-size: 2em;
    }

    span {
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }

  > .earn {
    color: rgb(37, 187, 135);
  }

  > .price {
    color: rgb(237, 67, 92);
  }

  > h3 {
    grid-column: 1 / 3;
    text-align: center;
    font-size: 1.5em;
    margin: 10px 30px;
  }

  > .yt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1 / 3;
    grid-row-gap: 10px;
  }

  .hint {
    color: #aaa;
    font-size: 0.8em;
    grid-column: 1 / 3;
  }
}

.sponsoring {
  width: 700px;
  > iframe {
    width: 100%;
  }

  max-width: 90vw;
}

.disclaimer {
  text-align: center;
  color: #aaa;
}
</style>

<template lang="pug">
.app
  h1 YouTube Sponsoring Calculator


  .sponsoring
    h2 Sponsored by <a href="https://store.steampowered.com/app/1318690/shapezio/" target="_blank">shapez.io</a>
    <iframe src="https://store.steampowered.com/widget/1318690/" allowtransparency frameborder="0" width="646" height="190"></iframe>

  .content

    .step
      h3 On which platform would you like to hire the Influencer?
      label
        input(type="radio", name="platform", v-model="platform", value="yt")
        | Youtube
      label
        input(type="radio", disabled, name="platform", v-model="platform", value="twitch")
        | Twitch (coming soon!)


    .step
      h3 Please give us some more information about the influencer
      label
        input(type="checkbox", v-model="highPricedCountry")
        | The viewers are mostly located in the US, Canada, France, Germany, or UK
        .explanation Those are the countries where people are much more likely to buy your game from. You can determine this based on the language and video comments.
      label
        input(type="checkbox", v-model="similarGenre")
        | The influencer has played games with a similar genre to my game before.
        .explanation This vastly increases the chance of people being interested in your game.
      label
        input(type="checkbox", v-model="onlyOneGame")
        | The influencer mostly only plays 1 or 2 different games.
        .explanation This vastly decreases the chance of people being interested in your game, since the influencer has a fanbase which is mostly interested in one exact game.
      label
        input(type="checkbox", v-model="playedBefore")
        | The influencer has played my game before.
        .explanation A second video or stream about a game usually gets much less views and traction.

    .step
      h3
        | We need to estimate the number of
        |
        template(v-if="platform === 'yt'") views
        template(v-if="platform === 'twitch'") viewers
        |
        | now

      template(v-if="platform === 'yt'")
        p Go to the influencers youtube channel and have a look at the 
          |
          strong past 10 videos which are at least 1 week old. 

        label.text
          p What is the <strong>HIGHEST</strong> amount of views a video had?
          input(type="number", placeholder="85000", min="10", step="1", v-model="ytMaxViews")
        label.text
          p What is the <strong>LOWEST</strong> amount of views a video had?
          input(type="number", placeholder="15000", min="10", step="1", v-model="ytMinViews")
        label.text
          p What is the <strong>SECOND LOWEST</strong> amount of views a video had?
          input(type="number", placeholder="15000", min="10", step="1", v-model="ytMinViewsSecond")

      template(v-if="platform === 'twitch'")
        p Find the streamer on <a href="https://twitchtracker.com/" target="_blank">twitchtracker</a>.

        label.text
          strong What is the <strong>average</strong> amount of viewers per stream?
          input(type="number", placeholder="50", min="10", step="1")
          .explanation You can find those in the "Streamer Profile" info box.

    .step
      h3 Now we need to define your source of income

      label
        input(type="checkbox", v-model="advertisements")
        | My game is a webgame and runs advertisements
        
      label
        input(type="checkbox", v-model="steamStore")
        | My game is sold on a store (steam, itch.io, ...)

    .step(v-if="advertisements")
      h3 Tell us a bit more about your advertisements

      label.text
        strong What do you earn on average (in USD) per 1000 users which visit your page?
        input(type="number", placeholder="0.87", min="0", step="0.01", v-model="adsEarnPer1000")
        .explanation A common value if you show a preroll ad is roughly $ 2.20


    .step(v-if="steamStore")
      h3 Tell us a bit more about your store sales

      label.text
        strong What do you earn on average (in USD) per unit sold?
        input(type="number", placeholder="2.75", min="0", step="0.01", v-model="storeEarnPerCopy")
        .explanation For steam, this is roughly 55% of the game price (After 30% share, 10% avg. vat, and 3% returns). So at $ 4.99 you roughly get $ 2.75 paid out.

  .result
    h3 Expectations (after one Month)

    template(v-if="platform === 'yt'")
    .yt
      .entry
        strong {{pretty(ytVideoViews)}}
        span Video views

      .entry
        strong {{prettyPrice(ytCPM)}}
        span Price per 1k views
        
      .entry(v-if="steamStore")
        strong {{pretty(ytConvertedStoreSales)}}
        span store sales
        
      .entry(v-if="advertisements")
        strong {{pretty(ytConvertedPageVisits)}}
        span page visits

    .entry.price
      strong {{prettyPrice(suggestedPrice)}}
      span Estimated Price

    .entry.earn
      strong {{prettyPrice(expectedEarnings)}}
      span Expected Earnings

    .hint This is just an estimate based on past experiences. A video can become way more successful than expected without an actual reason, or it can totally fail. I have had both cases, so please be careful on how you spend your money.

  .disclaimer This page just tries to give an estimate. This is no advice.
    br
    br
    | This calculator is provided by <a href="https://tobspr.io" target="_blank">tobspr IT Solutions</a>.

</template>

<script>
export default {
  name: "App",

  data: () => ({
    platform: "yt",

    highPricedCountry: true,
    similarGenre: true,
    onlyOneGame: false,
    playedBefore: false,

    twitchAvgViewers: 50,
    ytMinViews: 10000,
    ytMinViewsSecond: 12000,
    ytMaxViews: 40000,

    advertisements: false,
    steamStore: true,

    adsEarnPer1000: 2.2,
    storeEarnPerCopy: 2.75,
  }),

  methods: {
    prettyPrice(price) {
      if (price > 1000) {
        price = Math.round(price / 50) * 50;
      } else if (price > 100) {
        price = Math.round(price / 10) * 10;
      } else if (price > 10) {
        price = Math.round(price / 1) * 1;
      } else {
        price = Math.round(price * 10) / 10;
      }

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price);
    },

    pretty(num) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(num);
    },
  },

  computed: {
    ytVideoViews() {
      if (this.platform !== "yt") {
        return 0;
      }

      const min = this.ytMinViews;
      const max = this.ytMaxViews;
      const minSecond = this.ytMinViewsSecond;

      let views = min * 0.5 + minSecond * 0.35 + max * 0.15;

      if (max > 10 * minSecond) {
        // was just a one time popular video
        views = min * 0.5 + minSecond * 0.35 + max * 0.01;
      }

      if (this.onlyOneGame) {
        views *= 0.34;
      }

      if (!this.similarGenre) {
        views *= 0.91;
      } else {
        views *= 1.05;
      }

      if (this.playedBefore) {
        views *= 0.8;
      }

      return views;
    },

    ytConvertedPageVisits() {
      if (this.platform !== "yt") {
        return 0;
      }

      if (!this.advertisements) {
        return 0;
      }

      let conversionRate = 0.045;
      if (this.similarGenre) {
        conversionRate = 0.091;
      }

      return this.ytVideoViews * conversionRate;
    },

    ytConvertedStoreSales() {
      if (this.platform !== "yt") {
        return 0;
      }

      if (!this.steamStore) {
        return 0;
      }

      let conversionRate = 0.001;
      if (this.similarGenre) {
        conversionRate = 0.019;
      }

      if (!this.highPricedCountry) {
        conversionRate *= 0.08;
      }

      return this.ytVideoViews * conversionRate;
    },

    ytConvertedAds() {
      if (this.platform !== "yt") {
        return 0;
      }

      if (!this.advertisements) {
        return 0;
      }

      return (this.ytConvertedPageVisits * this.adsEarnPer1000) / 1000;
    },

    ytStoreSalesRevenue() {
      return this.ytConvertedStoreSales * this.storeEarnPerCopy;
    },

    ytCPM() {
      if (this.platform !== "yt") {
        return 0;
      }

      let base = 1;

      if (!this.highPricedCountry) {
        base = 0.15;
      }

      if (!this.similarGenre) {
        base *= 0.66;
      }

      if (this.playedBefore) {
        base *= 0.8;
      }

      if (this.ytVideoViews > 500000) {
        return base * 30;
      }

      if (this.ytVideoViews > 100000) {
        return base * 20;
      }

      if (this.ytVideoViews > 50000) {
        return base * 15;
      }

      if (this.ytVideoViews > 30000) {
        return base * 11.5;
      }

      if (this.ytVideoViews > 10000) {
        return base * 9.5;
      }

      return base * 15;
    },

    expectedEarnings() {
      if (this.platform === "yt") {
        return this.ytStoreSalesRevenue + this.ytConvertedAds;
      }
      return 0;
    },

    suggestedPrice() {
      if (this.platform === "yt") {
        return (this.ytCPM * this.ytVideoViews) / 1000;
      }
      return 0;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
